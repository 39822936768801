import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import ContactSection from './components/ContactSection';
import Classes from './pages/Classes';
import AboutUsPage from './pages/About';

function App() {
  return (
    <Router>
      <div className="min-h-screen">
      <ContactSection />
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/class" element={<Classes />} />
        <Route path="/about" element={<AboutUsPage />} />
      </Routes>
      <Footer />
    </div>

    </Router>
  );
}

export default App;
