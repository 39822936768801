import React, { useState } from 'react';

const ContactForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    branch: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.mobileNumber) {
      newErrors.mobileNumber = "Mobile Number is required";
    } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber = 'Mobile Number must be 10 digits';
    }
    if (!formData.branch) newErrors.branch = 'Branch selection is required';
    if (!formData.message) newErrors.message = 'Message is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      onClose();
      try {
        const response = await fetch('https://mastermindclass.in/api/mail.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData)
        });

        if (response.ok) {
          alert('Email sent successfully!');
          setFormData({
            name: '',
            mobileNumber: '',
            branch: '',
            message: ''
          });
        } else {
          alert('Failed to send email.');
        }
      } catch (error) {
        console.error('Error sending email:', error);
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" style={{ zIndex: "2002" }}>
      <div className="bg-green-500 p-6 rounded-lg max-w-md w-full relative overflow-y-auto max-h-screen">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-100 text-3xl font-bold">&times;</button>
        <h2 className="text-2xl font-semibold mb-4 text-center text-white">Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Parent's Name"
              className="p-2 w-full border border-gray-300 rounded-md bg-white text-black"
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
          </div>
          <div className="mb-2">
            <input
              type="text"
              id="mobileNumber"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              placeholder="Mobile Number"
              className="p-2 w-full border border-gray-300 rounded-md bg-white text-black"
            />
            {errors.mobileNumber && <p className="text-red-500 text-sm">{errors.mobileNumber}</p>}
          </div>
          <div className="mb-2">
            <select
              id="branch"
              name="branch"
              value={formData.branch}
              onChange={handleInputChange}
              className="p-2 w-full border border-gray-300 rounded-md bg-white text-black"
            >
              <option value="">Select Branch</option>
              <option value="tuition">Tuition</option>
              <option value="abacus">Abacus</option>
            </select>
            {errors.branch && <p className="text-red-500 text-sm">{errors.branch}</p>}
          </div>
          <div className="mb-2">
            <textarea
              id="message"
              name="message"
              rows="2"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Message"
              className="p-2 w-full border border-gray-300 rounded-md bg-white text-black"
            ></textarea>
            {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
          </div>
          <div className="text-center">
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
