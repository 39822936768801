// Navbar.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Logo from '../assets/logo.png'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const nav = [
    {id:1, name:"Home", link:"/"},
    {id:2, name:"Classes", link:"/class"},
    {id:2, name:"About", link:"/about"},
    {id:3, name:"Contact", link:"/contact"},
  ]
  return (
    <motion.nav
      className="bg-green-500 p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto flex justify-between items-center">
        <div className='flex items-center justify-center'>
        <img src={Logo} className=" w-10 md:w-20 h-10" alt="" srcset="" />
        <div className="text-white font-bold text-lg md:text-2xl">MasterMind Coaching Center</div>
        </div>
        <div className="md:hidden">
          <button
            onClick={toggleNavbar}
            className="focus:outline-none text-white"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
        <div className="hidden md:flex md:items-center text-lg">
          {nav.map((item,key)=>{return(
        <NavLink
        key={key}
  to={item.link}
  className={`text-white mx-4 px-3 py-1 hover:bg-white hover:bg-opacity-15 ${
    currentPath == item.link ? "bg-white bg-opacity-15" : ""
  }`}
>
  {item.name}
</NavLink>
        )})}
        </div>
      </div>
      <motion.div
        initial={false}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={{ duration: 0.5 }}
        className={`md:hidden px-4 pt-2 overflow-hidden`}
      >
        {nav.map((item, key)=>{return(
        <Link to={item.link} key={key} className={`text-white block mb-2 hover:bg-white hover:bg-opacity-15 px-3 py-1 ${currentPath == item.link ? "bg-white bg-opacity-15" : ""}`}>
          {item.name}
        </Link>
        )})}
      </motion.div>
    </motion.nav>
  );
};

export default Navbar;
