import React from 'react';
import { FaStar, FaStarHalf } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Carousel2 from "react-multi-carousel";
import proferssor from '../assets/HeadMaster.jpg'
import testimonial1 from '../assets/testimonials/testimonial1.jpg'
import testimonial2 from '../assets/testimonials/testimonial2.jpg'
import { Helmet } from 'react-helmet-async';
const AboutUsPage = () => {
  const teamMembers = [
    {
      name: "Rohini",
      position: "Founder & CEO",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget finibus odio, vitae volutpat libero.",
      imageUrl: "https://via.placeholder.com/150"
    },
    {
      name: "Mohamed Javith",
      position: "Head of Operations",
      bio: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
      imageUrl: "https://via.placeholder.com/150"
    },
    {
      name: "Mathew",
      position: "Lead Instructor",
      bio: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
      imageUrl: "https://via.placeholder.com/150"
    },
    {
      name: "Mukizhan",
      position: "Marketing Manager",
      bio: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.",
      imageUrl: "https://via.placeholder.com/150"
    }
  ];

  const testimonials = [
    {
      name: "Mukizhan",
      position: "Parent",
      comment: "Mastermind Tuition transformed my child's learning! The tutors are excellent, and my child's grades have improved significantly. Highly recommend!",
      avatarUrl: testimonial1,
      rating: 5
    },
    {
      name: "Mohamed Javith",
      position: "Student",
      comment: "Great experience! The personalized attention at Mastermind Tuition made a huge difference for my daughter. She's now more confident in her studies!",
      avatarUrl: testimonial1,
      rating: 4.5
    },
    {
      name: "Mathew",
      position: "Student",
      comment: "The abacus classes at Mastermind Tuition have been fantastic for my son. He loves learning and has developed impressive math skills!",
      avatarUrl: testimonial1,
      rating: 4.5
    },
    {
      name: "Rohini",
      position: "Student",
      comment: "Best decision ever! The tutors at Mastermind Tuition are dedicated and supportive. My child has shown amazing progress. Thank you!",
      avatarUrl: testimonial2,
      rating: 4.5
    }
  ];
  const principalDetails = {
    name: "Saranya  B",
    position: "Principal",
    bio: "Welcome to MasterMind, the best tuition center in Chennai, where excellence meets experience!With over a decade of dedicated teaching expertise, led by our principal Saranya B with 10 years of experience, we pride ourselves on providing top-notch education through both online and offline classes tailored to meet your unique needs. Our mission is to empower students to unlock their full academic potential and achieve their goals. At MasterMind, we believe in a holistic approach to education, combining rigorous academic training with personalized attention. Our experienced instructors are committed to fostering a supportive and engaging learning environment, ensuring that each student receives the guidance they need to succeed.",
    imageUrl: proferssor, // Adjust size as needed
    email: "saranyanivethaa@gmail.com",
    phone: "+919789543174",
  };
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
const [testimonialRef, testimonialInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  return (
    <><Helmet>
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-KWYBWWDT85"></script>
   <script>
     {`
       window.dataLayer = window.dataLayer || [];
       function gtag(){dataLayer.push(arguments);}
       gtag('js', new Date());
       gtag('config', 'G-KWYBWWDT85');
     `}
   </script>
     <title>About Mastermind Tuition | Premier Tuition Center in Chennai</title>
     <meta name="description" content="Learn about Mastermind Tuition, Chennai's premier tuition center dedicated to providing personalized education and expert guidance. Discover our mission and values!" />
     <link rel="canonical" href={window.location.href} />
     <meta property="og:title" content="About Mastermind Tuition | Premier Tuition Center in Chennai" />
     <meta property="og:description" content="Learn about Mastermind Tuition, Chennai's premier tuition center dedicated to providing personalized education and expert guidance. Discover our mission and values!" />
     <meta property="og:url" content={window.location.href} />
     <meta property="og:type" content="website" />
   </Helmet>
    <div className="container mx-auto py-12">
      <h2 className="text-3xl md:text-4xl font-bold text-green-700 text-center mb-8">About Us</h2>
      {/* <p className="text-lg text-gray-700 mb-8 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
      

{/* Principal Details Section */}
<motion.div 
initial={{ opacity: 0 }}
animate={{ opacity: 1 }}
transition={{ duration: 1.5 }}
className="principal-section bg-white border py-8 px-4 flex mx-10 md:flex-row flex-col justify-center">
      <div className="w-full md:w-1/4 mr-4">
        <img src={principalDetails.imageUrl} alt="Principal Banner" className="w-full rounded-lg" />
      </div>
      <div className="w-full md:w-3/4 text-green-600 mt-2">
        <div className="text-left">
          <h2 className="text-2xl font-bold mb-4 text-center">{principalDetails.name}</h2>
          <p className="text-lg text-green-800 mb-2">{principalDetails.bio}</p>
          <div className="">
            <h3 className="text-xl font-bold mb-2">Principal Details:</h3>
            <ul className='text-md'>
              <li><strong>Email:</strong> {principalDetails.email}</li>
              <li><strong>Phone:</strong> {principalDetails.phone}</li>
              {/* Add more details as needed */}
            </ul>
          </div>
        </div>
      </div>
    </motion.div>

    <div className="choose-us-section py-8 px-4 bg-green-500">
      <div className="max-w-4xl mx-auto text-center text-white">
        <h2 className="text-3xl font-bold mb-4">Why Choose Master Mind Coaching Center?</h2>
        <p className="text-lg text-white mb-6">
          At Master Mind Coaching Center, we are passionate about education and committed to your success. We understand that every student learns differently, and that's why we offer a unique learning environment that caters to individual needs. Here's what sets us apart:
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-black">
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-2">Proven Track Record of Success</h3>
            <p className="text-gray-700">We boast a history of exceptional results. Our students consistently achieve top marks and gain admission to their dream college.</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-2">Individual Attention</h3>
            <p className="text-gray-700">We believe that every student deserves personalized attention. Our experienced tutors take the time to understand your strengths and weaknesses, creating a customized learning plan that helps you excel.</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-2">Experienced Tutors</h3>
            <p className="text-gray-700">Our team comprises highly qualified and passionate educators with extensive experience. They are not only experts in their subjects but also skilled at making learning engaging and effective.</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-2">Dedicated Support</h3>
            <p className="text-gray-700">Your education doesn't stop at the classroom door. We offer ongoing support, including doubt-clearing sessions and even Sunday classes to address any lingering questions or challenges you might face.</p>
          </div>
        </div>
        {/* <button className="mt-8 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
          Learn More
        </button> */}
      </div>
    </div>

      {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
        {teamMembers.map((member, index) => (
          <motion.div
          key={index}
          className="bg-gray-100 rounded-lg overflow-hidden shadow-lg p-6"
          variants={cardVariants}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5, delay: index * 0.1 }}
        >
            <img src={member.imageUrl} alt={member.name} className="w-24 h-24 rounded-full mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-gray-800 mb-2">{member.name}</h3>
            <p className="text-sm text-gray-600 mb-2">{member.position}</p>
            <p className="text-sm text-gray-700">{member.bio}</p>
          </motion.div>
        ))}
      </div> */}

      <div className="mt-12" ref={testimonialRef} >
        <h2 className="text-3xl md:text-4xl font-bold text-green-700 text-center mb-8">Testimonials</h2>
        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            
           
            <motion.div 
            key={index} 
            className="bg-gray-100 rounded-lg overflow-hidden shadow-lg p-6 border border-green-800 border-opacity-25"
            variants={cardVariants}
            initial="hidden"
            animate={testimonialInView ? 'visible' : 'hidden'}>
              <div className="flex items-center mb-4">
                <img src={testimonial.avatarUrl} alt={testimonial.name} className="w-10 h-10 rounded-full mr-2" />
                <p className="text-gray-800 font-semibold">{testimonial.name}</p>
              </div>
              <p className="text-gray-700 italic mb-2">&quot;{testimonial.comment}&quot;</p>
              <p className="text-gray-600">{testimonial.position}</p>
              <div className="flex items-center mt-2">
                <div className="flex items-center">
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <FaStar key={i} className="text-yellow-500" />
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div> */}
        <Carousel2
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 2,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
              }
            }}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={false}
            transitionDuration={500}
            arrows={false}
            showThumbs={false}
          >
            {testimonials.map((testimonial, index) => (
              <motion.div 
              key={index} 
              className="bg-gray-100 rounded-lg overflow-hidden shadow-lg p-6 border mx-5 "
              variants={cardVariants}
              initial="hidden"
              animate={testimonialInView ? 'visible' : 'hidden'}>
                <div className="flex items-center mb-4">
                  <img src={testimonial.avatarUrl} alt={testimonial.name} className="w-20 h-20 rounded-full mr-2" />
                  <p className="text-gray-800 font-bold text-2xl">{testimonial.name}</p>
                </div>
                <p className="text-gray-700 italic mb-2">&quot;{testimonial.comment}&quot;</p>
                <p className="text-gray-600 font-bold">{testimonial.position}</p>
                <div className="flex items-center mt-2">
                  <div className="flex items-center">
                  {[...Array(Math.floor(testimonial.rating))].map((_, i) => (
  <FaStar key={i} className="text-yellow-500" />
))}
{testimonial.rating % 1 !== 0 && (
  <FaStarHalf className="text-yellow-500" />
)}
                  </div>
                </div>
              </motion.div>
            ))}
          </Carousel2>
      </div>
      
    </div>
    </>
  );
};

export default AboutUsPage;



