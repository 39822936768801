// Footer.js
import React from 'react';
import { motion } from 'framer-motion';
import { FaFacebookSquare, FaTwitter, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { Link, NavLink, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const nav = [
    {id:1, name:"Home", link:"/"},
    {id:2, name:"Classes", link:"/class"},
    {id:2, name:"About", link:"/about"},
    {id:3, name:"Contact", link:"/contact"},
  ]
  return (
    <motion.footer
      className="bg-green-500 text-white"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container py-8">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 justify-between mx-4">
          {/* About Us Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">About Us</h3>
            {/* <p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          </div>

          {/* Quick Links Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
            {nav.map((item, key)=>{return(
              <li><Link to={item.link} key={key} className={` hover:bg-white hover:bg-opacity-15 px-3 py-1 ${currentPath == item.link ? "bg-white bg-opacity-15" : ""}`}>
              {item.name}
            </Link></li>
        
        )})}
            </ul>
          </div>

          {/* Contact Us Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <p className="text-sm">Chennai, Tamilnadu<br />Email: saranyanivethaa@gmail.com<br />Phone: +91 9789543174</p>
          </div>

          {/* Social Media Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4 text-2xl">
              <a href="#" className="text-2xl"><FaFacebookSquare /></a>
              <a href="#" className="text-2xl"><FaInstagram /></a>
              <a href="https://wa.me/+919789543174?text=Hello%20there,%20I%20have%20a%20message%20for%20you!" className="text-2xl"><FaWhatsapp /></a>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="text-center mt-8">
          <p className="text-sm">&copy; 2024 Your Website. All rights reserved.</p>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
