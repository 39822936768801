import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import class1 from '../assets/classes/class1.jpg'
import class2 from '../assets/classes/class2.jpg'
import class3 from '../assets/classes/class3.jpg'
import class4 from '../assets/classes/class4.png'
import { Helmet } from 'react-helmet-async';

const ClassesPage = () => {
  const classes = [
    {
      title: "1st - 5th Grade Classes",
      description: "At MasterMind, our 1st to 5th-grade classes are designed to build a strong foundation in core subjects such as Mathematics, Science, English, and Social Studies. We employ engaging teaching methods and interactive learning activities to make education enjoyable and effective for young learners. Our goal is to nurture curiosity, creativity, and a love for learning from an early age.",
      age: "7 - 12 Years",
      totalSeats: 25,
      timings: "Monday to Friday, 4pm - 6pm",
      subjects: "English, Maths",
      imageUrl: class1
    },
    {
      title: "5th - 10th Grade Classes",
      description: "For students in 5th to 10th grade, MasterMind offers comprehensive tuition that focuses on in-depth understanding and mastery of subjects. Our curriculum covers Mathematics, Science, English, and Social Studies, with special attention to board exam preparation and critical thinking skills. Our experienced instructors provide personalized support to help each student achieve academic excellence and confidence.",
      age: "12 - 15 Years",
      totalSeats: 25,
      timings: "Monday to Friday, 6pm - 8pm",
      subjects: "English, Maths, Science",
      imageUrl: class2
    },
    {
      title: "10th - 12th Grade Classes",
      description: "MasterMind provides specialized tuition for 10th to 12th-grade students, focusing on advanced subject matter and exam preparation. Our curriculum includes Mathematics, Physics, Chemistry, Biology, English, and other elective subjects. We emphasize critical thinking, problem-solving, and analytical skills to ensure students are well-prepared for board exams and competitive entrance exams. Our dedicated instructors offer personalized guidance and mentoring to help students achieve their academic and career goals.",
      totalSeats: 25,
      age: "15 - 18 Years",
      timings: "Monday to Friday, 8pm - 10pm",
      subjects: "English, Maths, Science, Social Studies",
      imageUrl: class3
    },
    {
      title: "Abacus Classes",
      description: "MasterMind’s Abacus classes are designed to enhance the mathematical abilities of children through the ancient technique of abacus learning. These classes help improve concentration, memory, and mental calculation skills. Suitable for children of various age groups, our abacus program is a fun and effective way to boost cognitive development and mathematical proficiency",
      age: "7 - 15 Years",
      totalSeats: 20,
      timings: "Saturday(only), 10am - 12pm",
      subjects: "Abacus",
      imageUrl: class4
    }
  ];

  // Ref for the container element
  const containerRef = useRef(null);

  // State to track scroll position
  const [scrollY, setScrollY] = useState(0);

  // Effect to update scroll position on scroll
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    <Helmet>
       <script async src="https://www.googletagmanager.com/gtag/js?id=G-KWYBWWDT85"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-KWYBWWDT85');
        `}
      </script>
        <title>Tuition & Abacus Classes in Chennai - Mastermind Tuition</title>
        <meta name="description" content="Explore Mastermind Tuition's expert tuition and abacus classes in Chennai. Our personalized learning approach helps students excel academically. Enroll now!
" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content="Tuition & Abacus Classes in Chennai - Mastermind Tuition" />
        <meta property="og:description" content="Explore Mastermind Tuition's expert tuition and abacus classes in Chennai. Our personalized learning approach helps students excel academically. Enroll now!
" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
    <div className="container mx-auto py-12">
      <h2 className="text-3xl md:text-4xl font-bold text-green-700 text-center mb-8">Tuitions For Your Kids</h2>
      <div className='flex items-center justify-center'>
      {/* <p className='w-2/4 text-center'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. A nobis impedit consectetur consequuntur incidunt, harum expedita repudiandae voluptas qui nisi cum inventore! Repellat soluta quo magnam labore eligendi ratione voluptatem!</p> */}
      </div>
      <div
        ref={containerRef}
        className="max-w-screen-lg mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-8 mt-4"
      >
        {classes.map((classItem, index) => (
          <motion.div
            key={index}
            className="bg-green-400 rounded-lg overflow-hidden shadow-lg cursor-pointer"
            whileHover={{ scale: 1.05, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)' }}
            transition={{ duration: 0.3 }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
          >
            <img src={classItem.imageUrl} alt={classItem.title} className="w-full h-72 object-cover" />
            <div className="p-6 flex items-center flex-col">
              <h3 className="text-lg font-semibold mb-2 text-white">{classItem.title}</h3>
              <p className="text-sm mb-6 text-white">{classItem.description}</p>
              <table className="text-sm mb-4 text-white shadow-2xl border-2">
                <tbody className='bg-green-600'>
                  <tr>
                    <td className="border border-white px-4 py-2">Total Seats:</td>
                    <td className="border border-white px-4 py-2">{classItem.totalSeats}</td>
                  </tr>
                  <tr>
                    <td className="border border-white px-4 py-2">Timings:</td>
                    <td className="border border-white px-4 py-2">{classItem.timings}</td>
                  </tr>
                  <tr>
                    <td className="border border-white px-4 py-2">Subjects:</td>
                    <td className="border border-white px-4 py-2">{classItem.subjects}</td>
                  </tr>
                  <tr>
                    <td className="border border-white px-4 py-2">Age Of Kids:</td>
                    <td className="border border-white px-4 py-2">{classItem.age}</td>
                  </tr>
                </tbody>
              </table>
              <div className="flex justify-center mt-4">
                <Link to="/contact"><button className="bg-white text-green-500 px-4 py-2 rounded-full hover:bg-green-500 hover:text-white transition duration-300">Contact Us</button></Link>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
    </>
  );
};

export default ClassesPage;
