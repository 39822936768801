import React from 'react';
import ContactForm from './ContactForm';

const ContactPopup = ({ isOpen, onClose }) => {
  return (
    <>
      {isOpen && <ContactForm onClose={onClose} />}
    </>
  );
};

export default ContactPopup;
