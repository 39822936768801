import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useInView } from 'react-intersection-observer';
import Carousel2 from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ContactPopup from '../components/ContactPopup';
import { GiSpyglass } from 'react-icons/gi';
import { AiOutlineEye } from 'react-icons/ai';
import { FaBullseye, FaFlag, FaEye } from 'react-icons/fa';
import services1 from '../assets/services/services1.jpg'
import services2 from '../assets/services/services2.jpg'



import banner1 from '../assets/banner/banner1.jpg'
import banner2 from '../assets/banner/banner2.jpg'
import banner3 from '../assets/banner/banner3.jpg'
import banner4 from '../assets/banner/banner4.jpg'

import feature1 from '../assets/features/features1.png'
import feature2 from '../assets/features/features2.png'
import feature3 from '../assets/features/features3.png'
import feature4 from '../assets/features/features4.png'
import feature5 from '../assets/features/features5.png'
import feature6 from '../assets/features/features6.png'

import gallery1 from '../assets/gallery/gallery1.jpg'
import gallery2 from '../assets/gallery/gallery2.jpg'
import gallery3 from '../assets/gallery/gallery3.jpg'
import gallery4 from '../assets/gallery/gallery4.jpg'
import gallery5 from '../assets/gallery/gallery5.jpg'
import gallery6 from '../assets/gallery/gallery6.jpg'
import gallery7 from '../assets/gallery/gallery7.jpg'
import gallery8 from '../assets/gallery/gallery8.jpg'
import gallery9 from '../assets/gallery/gallery9.jpg'

import sstudent1 from '../assets/success_students/success_student1.jpg'
import sstudent2 from '../assets/success_students/success_student2.jpg'
import sstudent3 from '../assets/success_students/success_student3.jpg'
import sstudent4 from '../assets/success_students/success_student4.jpg'
import sstudent5 from '../assets/success_students/success_student5.jpg'

import HeadMaster from '../assets/HeadMaster1.jpg'
import { Helmet } from 'react-helmet-async';
const Home = () => {

  const missionIcon = <GiSpyglass size={32} />;
  const visionIcon = <AiOutlineEye size={32} />;

  // Dummy carousel data
  const carouselItems = [
    { id: 1, imageUrl: banner1, title: 'First Slide', description: 'Welcome to our website!' },
    { id: 2, imageUrl: banner2, title: 'Second Slide', description: 'Discover amazing features.' },
    { id: 3, imageUrl: banner3, title: 'Third Slide', description: 'Join us today.' },
    { id: 3, imageUrl: banner4, title: 'fourth Slide', description: 'Join us today.' },
  ];

  const services = [
    { id: 1, title: "Tuition Class", description: "Welcome to the Mastermind Tuition Center, the best tuition center in Chennai, where excellence meets experience! With over a decade of dedicated teaching expertise, we offer top-notch online and offline classes tailored to your needs. Join us to unlock your full academic potential!", timing:"Everyday 5:30pm to 8:30pm", quick_access: "Early classes: 5:30-7:00 AM if needed", imageUrl: services1},
    { id: 2, title: "Abacus Class", description: "Discover the power of mental math with our specialized abacus classes, offered in collaboration with the prestigious Indian Abacus Academy. Elevate your mathematical skills and cognitive abilities with our proven techniques, led by experienced instructors.", timing:"Every Saturday 2 hrs 15 mins", quick_access: "Every Saturday", imageUrl: services2}
  ];


  const futures = [
    { image: feature2, title: "Experience staff", description: "At our tuition center, each student brings a wealth of knowledge and expertise to create a supportive learning environment. Our staff is committed to nurturing individual strengths and fostering a love for learning in every student" },

    { image: feature6, title: "Abacus Class", description: "Elevate your mathematical skills and cognitive abilities with our proven techniques, led by experienced instructors. Join us and unleash your inner math genius!" },
    { image: feature1, title: "Customer Support", description: "we're dedicated to providing exceptional customer support tailored to your learning needs. Our team is here to offer personalized assistance, answer your questions, and ensure you have a seamless educational experience. Your success is our top priority!" },

    { image: feature3, title: "Parents Meeting", description: "Parent's meeting Our upcoming parents' meeting at the tuition center aims to foster collaboration and provide insights into your child's progress and development. Join us to discuss educational goals, strategies, and support for your child's academic journey" },
    { image: feature4, title: "Online Class", description: "I'm taking +2 student's on weekend classes. Even I can prepare the student's for neet exam along with syllabus They can crack the paper of almost above 600 questions" },
    // { image: feature5, title: "Practical Lab", description: "Welcome to our lab classes designed for +2 and +1 students! In these sessions, we will delve into practical experiments and hands-on activities tailored to enhance your understanding of scientific concepts." },
  ]

  const students = [
    { image: sstudent1, title: "This is a title", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus voluptatum odit, esse deserunt nesciunt aspernatur animi consectetur ut tenetur at cupiditate pariatur unde rem alias vero excepturi minus fugit sint!" },
    { image: sstudent2, title: "This is a second title", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus voluptatum odit, esse deserunt nesciunt aspernatur animi consectetur ut tenetur at cupiditate pariatur unde rem alias vero excepturi minus fugit sint!" },
    { image: sstudent3, title: "This is a third title", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus voluptatum odit, esse deserunt nesciunt aspernatur animi consectetur ut tenetur at cupiditate pariatur unde rem alias vero excepturi minus fugit sint!" },
    { image: sstudent4, title: "This is a fourth title", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus voluptatum odit, esse deserunt nesciunt aspernatur animi consectetur ut tenetur at cupiditate pariatur unde rem alias vero excepturi minus fugit sint!" },
    { image: sstudent5, title: "This is a seventh title", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus voluptatum odit, esse deserunt nesciunt aspernatur animi consectetur ut tenetur at cupiditate pariatur unde rem alias vero excepturi minus fugit sint!" },
  ]
  const galleryItems = [
    { id: 1, imageUrl: gallery1, title: 'Image 1' },
    { id: 2, imageUrl: gallery2, title: 'Image 2' },
    { id: 3, imageUrl: gallery3, title: 'Image 3' },
    { id: 4, imageUrl: gallery4, title: 'Image 4' },
    { id: 5, imageUrl: gallery5, title: 'Image 5' },
    { id: 6, imageUrl: gallery6, title: 'Image 6' },
    { id: 7, imageUrl: gallery7, title: 'Image 7' },
    { id: 8, imageUrl: gallery8, title: 'Image 8' },
    { id: 9, imageUrl: gallery9, title: 'Image 9' },
  ];

  const f_o_journies = [
    { id:1, title: "Mission", description: "Our mission is to inspire and empower students through personalized learning, fostering academic excellence and lifelong success.", icon:  <FaBullseye size={50}/>},
    { id:2, title: "Vision", description: "Our vision is to create a supportive learning community where every student achieves their full potential and is equipped for future success.", icon:  <FaEye size={50}/>},
    // { id:3, title: "Objectives", description: "We aim to provide personalized exam prep and skill development for students, fostering growth, excellence, and empowerment in a supportive environment.", icon:  <FaFlag size={50}/>}

    
  ]

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [featureRef, featureInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [principalRef, principalInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [galleryRef, galleryInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [studentRef, studentInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isContactOpen, setIsContactOpen] = useState(false);

  useEffect(()=>{
    setTimeout(()=>{
      setIsContactOpen(true);
    },3000)
  },[])
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const toggleContactPopup = () => {
    setIsContactOpen(!isContactOpen);
  };
  const carouselStyles = {
    maxHeight: '10px', // Adjust the value to decrease the image height
  };

  const missionAnimation = useAnimation();
  const visionAnimation = useAnimation();
  const [missionRef, missionInView] = useInView({ threshold: 0.5 });
  const [visionRef, visionInView] = useInView({ threshold: 0.5 });

  useEffect(() => {
    const controls = async () => {
      if (missionInView) {
        await missionAnimation.start({ y: 0, scale: 1.1 });
        await missionAnimation.start({ y: 0, scale: 1, transition: { duration: 0.3 } });
      }
      if (visionInView) {
        await visionAnimation.start({ y: 0, scale: 1.1 });
        await visionAnimation.start({ y: 0, scale: 1, transition: { duration: 0.3 } });
      }
    };

    controls();
  }, [missionInView, visionInView]);

  return (
    <div>
       <Helmet>
       <script async src="https://www.googletagmanager.com/gtag/js?id=G-KWYBWWDT85"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-KWYBWWDT85');
        `}
      </script>
        <title>Best Tuition Center in Chennai - Mastermind Tuition</title>
        <meta name="description" content="Mastermind Tuition is the best tuition center in Chennai, offering expert coaching and personalized learning for students. Achieve academic excellence with our experienced tutors" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content="Best Tuition Center in Chennai - Mastermind Tuition" />
        <meta property="og:description" content="Mastermind Tuition is the best tuition center in Chennai, offering expert coaching and personalized learning for students. Achieve academic excellence with our experienced tutors" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Carousel showIndicators={false} showArrows={true} showThumbs={false} autoPlay={true} interval={3000} infiniteLoop={true} style={carouselStyles}>
        {carouselItems.map((item, key) => (
          <div key={key}>
            <motion.img
              src={item.imageUrl}
              alt={item.title}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
            />
            <motion.p
              className="bg-green-700 text-white p-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 5 }}
            >
              {item.description}
            </motion.p>
          </div>
        ))}
      </Carousel>
      {/* Services Section */}
      <div ref={ref} className="bg-gray-200 py-12">
        <div className="container mx-auto">
          <h2 className="text-center mb-8 text-3xl md:text-4xl font-bold text-green-700">Our Services</h2>
          <motion.div
            className="flex justify-center md:flex-row flex-col items-center gap-5"
            variants={containerVariants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
          >
            {services.map((service, key) => (
              <motion.div
                key={key}
                whileHover={{ scale: 1.02 }} // Scale effect on hover
                className="max-w-md bg-green-500 rounded-lg overflow-hidden shadow-lg mx-4 cursor-pointer text-white"
                variants={containerVariants}
              >
                <img src={service.imageUrl} alt="" className=''/>
                <div className="px-6 py-4">
                  <div className="font-bold text-xl text-center">{service.title}</div>
                  <div className="font-bold  text-base  text-center text-yellow-300">{service.timing}</div>
                  <div className="font-bold text-base text-center mb-2 text-white border-green-400">{service.quick_access}</div>
                  <p className=" text-base font-medium">{service.description}</p>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>

      {/* Futures Section */}
      <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2.5 }}
      className="bg-gray-100 py-12" ref={featureRef} >
        <div className="container mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-green-700 text-center mb-8">Features</h2>
          <Carousel2
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 4,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 3,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
              }
            }}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            transitionDuration={500}
            showArrows={true}
            showThumbs={false}
          >
            {futures.map((future, index) => (
              <motion.div
                key={index}
                className="p-4 relative"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                variants={containerVariants}
                initial="hidden"
                animate={featureInView ? 'visible' : 'hidden'}
              >
                <div className="bg-white rounded-lg shadow-lg relative">
                  <img src={future.image} alt={future.title} className="w-full h-64 object-cover rounded-md " />
                  <div className="p-4 absolute bottom-0 left-0 right-0 bg-black bg-opacity-30 h-full" style={{ opacity: hoveredIndex === index ? 0 : 1, transition: 'opacity 0.3s ease-in-out text-white' }}>
                    <h3 className="p-4 absolute bottom-0 left-0 right-0 overflow-hidden  w-full text-white font-bold">{future.title}</h3>
                  </div>
                  <div className={`p-4 absolute bottom-0 left-0 right-0 overflow-hidden bg-green-500 rounded-md h-full ${hoveredIndex === index ? "opacity-80" : "opacity-0"}`} style={{ transform: hoveredIndex === index ? 'translateY(0)' : 'translateY(100%)', transition: 'transform 0.8s ease-in-out' }}>
                    <h3 className="font-semibold text-xl mb-2 text-white">{future.title}</h3>
                    <p className="text-white">{hoveredIndex === index ? future.description : ''}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </Carousel2>
        </div>
      </motion.div>
      {/* priciple details */}
      <motion.div
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ duration: 2.5 }}
  className="bg-gray-200 py-12"
  ref={principalRef}
>
  <div className="container mx-auto">
    <h2 className="text-3xl md:text-4xl font-bold text-green-700 text-center mb-8">Principal Details</h2>
    <motion.div
      className="flex flex-col items-center md:flex-row md:justify-center mx-5"
      variants={containerVariants}
      initial="hidden"
      animate={principalInView ? 'visible' : 'hidden'}
    >
      <motion.img
        src={HeadMaster}
        alt="Head Master"
        className="rounded-full w-80 h-80 mb-4 md:mr-4 md:mb-0"
      />
      <div className="text-center md:text-left bg-green-500 p-4 rounded-md flex flex-col items-center text-white"> 
        <motion.p className="text-2xl md:text-4xl font-semibold mb-2" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}>Saranya B</motion.p>
        <motion.p className=" text-base text-left" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.7 }}>With over a decade of dedicated teaching expertise, led by our principal Saranya B, who brings 10 years of experience to the table, we pride ourselves on providing top-notch education through both online and offline classes tailored to meet your unique needs. Our expert faculty utilizes innovative teaching methods and personalized approaches to ensure each student receives the support they need to excel. Our mission is to empower students to unlock their full academic potential, fostering a love for learning and guiding them toward achieving their educational and personal goals. At our institution, every student’s success is our ultimate achievement..</motion.p>
        {/* <motion.p className=" font-medium text-base" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.9 }}>Contact: johndoe@example.com</motion.p> */}

        <motion.button
          onClick={toggleContactPopup}
          className="bg-green-800 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full mt-4"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Contact Us
        </motion.button>
      </div>
    </motion.div>
  </div>
</motion.div>


<motion.div 
initial={{ opacity: 0 }}
animate={{ opacity: 1 }}
transition={{ duration: 2.5 }}
className="bg-gray-100 py-12" ref={galleryRef}>
  <div className="container mx-auto">
    <h2 className="text-3xl md:text-4xl font-bold text-green-700 text-center mb-8">Gallery</h2>
    <motion.div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"
                transition={{ duration: 1, delay: 0.5 }}
                variants={containerVariants}
                initial="hidden"
                animate={galleryInView ? 'visible' : 'hidden'}>
      {galleryItems.map((item) => (
        <motion.div key={item.id}
                    // whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
                    whileTap={{ scale: 0.95 }}
                    className='bg-green-300 overflow-hidden rounded-lg'>
          <img src={item.imageUrl} alt={item.title} className="w-full h-auto object-cover shadow-lg p-2 hover:scale-110 transition-all ease-in-out duration-300 overflow-hidden rounded-2xl" style={{ maxHeight: '200px' }} />
          {/* <p className="text-center mt-2">{item.title}</p> */}
        </motion.div>
      ))}
    </motion.div>
  </div>
</motion.div>
 {/* Mission and Vision Section */}
 <div className="bg-gray-200 py-12">
        <div className="container mx-auto">
          <h2 className="text-center mb-8 text-3xl md:text-4xl font-bold text-green-700">Foundation of Our Journey</h2>
          <div className="flex justify-center md:flex-row flex-col items-center">
            {/* Mission */}
         {f_o_journies.map((foj, key)=>{return(

            <motion.div
              key={key}
              ref={missionRef}
              className="relative overflow-hidden flex items-center justify-center mr-8"
              animate={missionAnimation}
              initial={{ y: -100, scale: 0.8 }}
              style={{ width: '300px', height: '300px', borderRadius: '50%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
            >
              <div className="bg-gradient-to-r from-green-400 to-green-500 rounded-full p-8 text-white flex flex-col items-center justify-center h-full">
                <div className="mt-4">
                  {foj.icon}
                </div>
                <h3 className="text-xl font-bold mb-2 ">{foj.title}</h3>
                <p className="text-sm md:text-base text-center">
                  {foj.description}
                </p>
              </div>
            </motion.div>
            )})}

            {/* Vision */}
            {/* <motion.div
              ref={visionRef}
              className="relative overflow-hidden flex items-center justify-center"
              animate={visionAnimation}
              initial={{ y: -100, scale: 0.8 }}
              style={{ width: '300px', height: '300px', borderRadius: '50%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
            >
              <div className="bg-gradient-to-r from-blue-400 to-blue-500 rounded-full p-8 text-white flex flex-col items-center justify-center h-full">
                <div className="mt-4">
                  <FaEye size={48} />
                </div>
                <h3 className="text-lg font-semibold mb-2">Our Vision</h3>
                <p className="text-sm md:text-base text-center">
                  Our vision is to inspire and nurture excellence, motivation, and lifelong learning. We aim to improve all slow learners to an upper grade of values.
                </p>
              </div>
            </motion.div> */}
          </div>
        </div>
      </div>


 {/* our success student Section */}
 <motion.div initial={{ opacity: 0 }}
animate={{ opacity: 1 }}
transition={{ duration: 2.5 }}
className="bg-gray-100 py-12" ref={studentRef}>
        <div className="container mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-green-700 text-center mb-8">Our Success Students</h2>
          <Carousel2
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
              }
            }}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            transitionDuration={500}
            showArrows={true}
            showThumbs={false}
          >
            {students.map((future, index) => (
              <motion.div
                key={index}
                className="p-2 relative shadow-2xl"
                variants={containerVariants}
                initial="hidden"
                animate={studentInView ? 'visible' : 'hidden'}
              >
                <div className="bg-white relative p-1 ">
                  <img src={future.image} alt={future.title} className="w-full h-[30rem] object-cover hover:scale-105 transition-all ease-out duration-200 rounded-lg" />
                  <div className="p-4 absolute bottom-0 left-0 right-0" style={{ opacity: hoveredIndex === index ? 0 : 1, transition: 'opacity 0.3s ease-in-out text-white' }}>
                  </div>
                </div>
              </motion.div>
            ))}
          </Carousel2>
        </div>
      </motion.div>
      <ContactPopup isOpen={isContactOpen} onClose={toggleContactPopup} />
    </div>
  );
};

export default Home;
