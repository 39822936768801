import React from 'react';
import { motion } from 'framer-motion';
import { FaFacebookSquare, FaTwitter, FaWhatsapp, FaInstagram } from 'react-icons/fa';

const ContactSection = () => {
  return (
    <div className=" top-0 z-50" style={{zIndex:"2000"}}>
      {/* Contact Us section */}
      <div className="bg-green-950 py-2 text-white text-center opacity-70 flex justify-between px-2 w-full">
        <div>For inquiries, contact us on : +919789543174</div>
        {/* Social media icons */}
        <div className="flex justify-cenFter mt-2 text-2xl">
          <a href="#" className="text-white mx-2">
            <FaFacebookSquare />
          </a>
          <a href="https://wa.me/+919789543174?text=Hello%20there,%20I%20have%20a%20message%20for%20you!" className="text-white mx-2">
            <FaWhatsapp />
          </a>
          <a href="#" className="text-white mx-2">
            <FaInstagram />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
