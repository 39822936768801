import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobileNumber: '',
    branch: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.mobileNumber) {
      newErrors.mobileNumber = "Mobile Number is required";
    } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber = 'Mobile Number must be 10 digits';
    }
    if (!formData.branch) newErrors.branch = 'Branch selection is required';
    if (!formData.message) newErrors.message = 'Message is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch('https://mastermindclass.in/api/mail.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData)
        });

        if (response.ok) {
          alert('Email sent successfully!');
          setFormData({
            name: '',
            mobileNumber: '',
            branch: '',
            message: ''
          });
        } else {
          alert('Failed to send email.');
        }
      } catch (error) {
        console.error('Error sending email:', error);
      }
    }
  };
  return (
    <>
    <Helmet>
       <script async src="https://www.googletagmanager.com/gtag/js?id=G-KWYBWWDT85"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-KWYBWWDT85');
        `}
      </script>
        <title>Contact Mastermind Tuition | Best Tuition Center in Chennai</title>
        <meta name="description" content="Get in touch with Mastermind Tuition, the best tuition center in Chennai. Have questions about our courses? Contact us today for more information!" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content="Contact Mastermind Tuition | Best Tuition Center in Chennai" />
        <meta property="og:description" content="Get in touch with Mastermind Tuition, the best tuition center in Chennai. Have questions about our courses? Contact us today for more information!" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
    <div className="container mx-auto py-12">
      <h2 className="text-3xl md:text-4xl font-bold text-green-700 text-center mb-8">Contact Us</h2>
      <div className="max-w-4xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="p-6">
              <h3 className="text-xl font-bold mb-4">Send us a message</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                  <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} placeholder="Name" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
                  {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                </div>
                <div className="mb-4">
                  <label htmlFor="mobile" className="block text-gray-700 text-sm font-bold mb-2">Mobile</label>
                  <input type="text" id="mobileNumber" name="mobileNumber" value={formData.mobileNumber} onChange={handleInputChange} placeholder="Mobile Number" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            {errors.mobileNumber && <p className="text-red-500 text-sm">{errors.mobileNumber}</p>}
                </div>




                <div className="mb-4">
                  <label htmlFor="mobile" className="block text-gray-700 text-sm font-bold mb-2">Mobile</label>
                  <select id="branch" name="branch" value={formData.branch} onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" >
                    <option value="">Select Branch</option>
                    <option value="tuition">Tuition</option>
                    <option value="abacus">Abacus</option>
                  </select>
            {errors.mobileNumber && <p className="text-red-500 text-sm">{errors.mobileNumber}</p>}
                </div>
                <div className="mb-4">
                  <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">Message</label>
                  <textarea id="message" name="message" rows="2" value={formData.message} onChange={handleInputChange} placeholder="Message" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
                    {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                </div>
                <div className="flex justify-center">
                  <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Send Message</button>
                </div>
              </form>
            </div>
          </div>
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="p-6">
              <h3 className="text-xl font-bold mb-4">Contact Information</h3>
              <div className="flex items-center mb-4">
                <FaPhone className="text-green-500 mr-4" />
                <div>
                  <p className="text-gray-700 font-semibold">Phone</p>
                  <p className="text-gray-600">+91 9789543174</p>
                </div>
              </div>
              <div className="flex items-center mb-4">
                <FaEnvelope className="text-green-500 mr-4" />
                <div>
                  <p className="text-gray-700 font-semibold">Email</p>
                  <p className="text-gray-600">saranyanivethaa@gmail.com</p>
                </div>
              </div>
              <div className="flex items-center">
                <FaMapMarkerAlt className="text-green-500 mr-4" />
                <div>
                  <p className="text-gray-700 font-semibold">Address</p>
                  <p className="text-gray-600">Chennai, Tamilnadu</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="map-container mt-4">
    <iframe
      title="Google Map Embed"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248756.07885882704!2d80.20901174999999!3d13.04752545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265ea4f7d3361%3A0x6e61a70b6863d433!2sChennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1714936010215!5m2!1sen!2sin"
      width="100%" // Use 100% width
      height="450"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
  {/* <div className="map-container mt-4">
    <iframe
      title="Google Map Embed"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248756.07885882704!2d80.20901174999999!3d13.04752545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265ea4f7d3361%3A0x6e61a70b6863d433!2sChennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1714936010215!5m2!1sen!2sin"
      width="100%" // Use 100% width
      height="450"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe> */}
  {/* </div> */}
    </div>
    </>
  );
};

export default ContactUsPage;
